import React from "react"
import Contact from "../components/contact/contact"
import InnerHeader from "../components/InnerHeader"
import Breadcrumb from "../components/breadcrumb"
import InnerPage from "../components/innerPage"
import { Helmet } from "react-helmet"

const ContactPage = () => {

  return (
    <InnerPage>
      <Helmet>
        <title>Contact</title>
        <meta name="Description" content="Restaurant Passion contact, email contact@passion-restaurant.ro,
        adresa: Bulevardul Gheorghe Doja 51, Târgu Mureș, telefon: 0725215610"/>
      </Helmet>
      <InnerHeader>
        <Breadcrumb label="Contact"  />
      </InnerHeader>
      <Contact />
    </InnerPage>
  )
}

export default ContactPage
